import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  MenuButton,
  DataGrid,
  IconButton
} from 'views/components/elements';
import { useStudentListStore } from 'store/student';
import { Attribute, FileDetail, Student } from 'models';
import { ButtonGroup } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import CachedIcon from '@mui/icons-material/Cached';
import { GridColDef, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { Icon } from 'views/components/icon';
import { FileUploadDialog } from 'views/dialogs';
import { useNavigate } from 'react-router-dom';
import StudentEditorDialog from 'views/dialogs/StudentEditor';
import { useAccountStore, useAttributeStore, useInstituteStore } from 'store';
import { createCSVBlobURL, currencyFormat, dateTimeFormat } from 'views/shared/utils/string-utils';
import AdvancedFilterDialog from 'views/components/elements/AdvancedFilterDialog';
import { Active, FlexRow, Inactive } from 'views/components/styled';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const StudentList = () => {
  const [importOpen, setImportOpen] = useState(false);
  const { student, fetchStudentList, importStudents } = useStudentListStore();
  const { attributes, fetchAttributes } = useAttributeStore();
  const { hasAccess } = useAccountStore();
  const { institute } = useInstituteStore();
  const navigate = useNavigate();
  const [editorOpen, setEditorOpen] = useState(false);
  const [advSearchOpen, setAdvSearchOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { students: studentList, filter: studentFilter, hasNextPage } = student;

  useEffect(() => {
    if (!studentList.length) {
      fetchStudentList();
    }
    if (!attributes.length) {
      fetchAttributes();
    }
  }, []);

  const columns = [
    {
      field: 'roll_number',
      headerName: 'roll no',
      width: 150,
      sortable: true,
      disableColumnMenu: true
    },
    {
      field: 'first_name',
      headerName: 'name',
      valueGetter: params => `${params.row.first_name} ${params.row.last_name}`,
      sortable: true,
      width: 200,
      disableColumnMenu: true
    },
    {
      field: 'is_active',
      headerName: 'status',
      renderCell: params =>
        params.row.is_active ? <Active>Active</Active> : <Inactive>Inactive</Inactive>,
      width: 120,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'total_amount',
      headerName: 'total fee',
      width: 120,
      align: 'right',
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: params => currencyFormat(params.value)
    },
    {
      field: 'captured_amount',
      headerName: 'fee paid',
      width: 120,
      align: 'right',
      sortable: false,
      disableColumnMenu: true,
      valueFormatter: params => currencyFormat(params.value)
    },
    {
      field: 'amount_capturable',
      headerName: 'balance amount',
      width: 150,
      align: 'right',
      sortable: true,
      disableColumnMenu: true,
      valueFormatter: params => currencyFormat(params.value)
    },
    {
      field: 'last_receipt_date_time',
      headerName: 'last payment',
      width: 150,
      sortable: true,
      disableColumnMenu: true,
      valueFormatter: params => dateTimeFormat(params.value, 'date')
    },
    {
      field: 'created_date_time',
      headerName: 'created',
      width: 120,
      sortable: true,
      disableColumnMenu: true,
      valueFormatter: params => dateTimeFormat(params.value, 'date')
    }
  ] as GridColDef<Student>[];

  const handleSort = (model: GridSortModel) => {
    const item = model[0];
    const filter = Object.assign({}, studentFilter, {
      sortColumn: item.field,
      sortDirection: item.sort,
      page: 1,
    });

    fetchStudentList(filter);
  };

  const handleNextPage = () => {
    const filter = Object.assign({}, studentFilter, {
      page: studentFilter.page + 1,
    });
    fetchStudentList(filter);
  };

  // search box
  const filter = { query: studentFilter.query || '' };
  const handleSubmit = (values: typeof filter) => {
    const updatedFilter = Object.assign({}, filter, {
      page: 1,
      query: values.query,
    });

    fetchStudentList(updatedFilter);
  };

  const studentAttributes = attributes.filter(a => a.is_active && a.usages.some(u => u.category === 'Student'));

  const handleImport = () => {
    setImportOpen(true);
  };

  const handleFileUpload = async (value?: FileDetail) => {
    setImportOpen(false);
    if (value) {
      importStudents(value);
    }
  };

  const handleExport = () => {
    console.log('Export clicked');
  };

  const handleRowClick = (params: GridRowParams<Student>) => {
    navigate(`/students/${params.row.student_id}`);
  };

  const handleAdd = () => {
    setEditorOpen(true);
  };

  const handleClose = () => {
    setEditorOpen(false);
  };

  const handleAdvancedSearchClick = () => {
    setAdvSearchOpen(true);
  };

  const handleAdvancedSearchClose = () => {
    setAdvSearchOpen(false);
  };

  const clearFilter = () => {
    setIsFilterApplied(false);
    fetchStudentList();
  };

  const customAttributes = studentAttributes.map(a => ({
    field: a.code,
    headerName: a.name,
    width: 150,
    valueGetter: params => {
      let value = params.row.metadata[a.code];

      if (a.input_type === 'List') {
        value = a.values.find(v => v.value === value)?.label;
      }

      if (a.data_type === 'Date') {
        value = dateTimeFormat(value, 'date');
      }

      return value;
    },
    sortable: false,
    disableColumnMenu: true
  }) as GridColDef<Student>);

  columns.push(...customAttributes);

  const isSchool = institute?.institute_type === 'School';
  const sampleFileUrl = buildSampleFile(studentAttributes, isSchool);
  const hasStudentCreateAccess = hasAccess(BEHAVIOR_ACTIONS.StudentCreate);
  const hasAdvancedFilters = studentAttributes.some(a => a.is_allow_filter);
  const sortModel = [] as GridSortModel;

  if (studentFilter.sortColumn) {
    sortModel.push({ field: studentFilter.sortColumn, sort: studentFilter.sortDirection });
  }

  return (
    <div className='custom-table-wrapper'>
      <div className='head'>
        <h1>Students</h1>
      </div>
      <div className='row action'>
        <FlexRow>
          <Form
            initialValues={filter}
            onSubmit={handleSubmit}
            validateOnChange={false}
          >
            <Input name='query' placeholder='Search Students' startAdornment={<SearchIcon />} />
          </Form>
          {hasAdvancedFilters && <IconButton onClick={handleAdvancedSearchClick}>
            <FilterListIcon />
          </IconButton>}
          {isFilterApplied && (
            <Button variant='text' color='secondary' onClick={clearFilter}>
              Clear filters
            </Button>
          )}
        </FlexRow>
        <AdvancedFilterDialog
          open={advSearchOpen}
          onClose={handleAdvancedSearchClose}
          searchModule={'Student'}
          setIsFilterApplied={setIsFilterApplied}
        />
        <FlexRow>
          <div>
            {studentList.length} of {student.total}
          </div>
          <IconButton title='Load More' disabled={!hasNextPage} onClick={handleNextPage}>
            <CachedIcon />
          </IconButton>
          <IconButton title='Refresh'>
            <RefreshIcon />
          </IconButton>
          <IconButton title='Preferences'>
            <SettingsIcon />
          </IconButton>
          <ButtonGroup variant='contained'>
            <Button
              startIcon={<Icon iconValue='person_add' icon='person_add_icon' />}
              onClick={handleAdd}
              disabled={!hasStudentCreateAccess}>
              Add Student
            </Button>
            <MenuButton
              menus={[
                {
                  label: 'Import',
                  icon: <Icon icon='download_icon' iconValue='download' />,
                  onClick: handleImport,
                },
                {
                  label: 'Export',
                  icon: <Icon icon='upload_icon' iconValue='upload' />,
                  onClick: handleExport,
                },
              ]}
            >
              <Icon icon='expand_more_icon' iconValue='expand_more' />
            </MenuButton>
          </ButtonGroup>
        </FlexRow>
      </div>
      <DataGrid
        columns={columns}
        rows={studentList}
        getRowId={r => r.student_id}
        density='compact'
        disableColumnFilter
        onRowClick={handleRowClick}
        sortingMode='server'
        onSortModelChange={handleSort}
        sortModel={sortModel}
      />
      <FileUploadDialog
        open={importOpen}
        title='Import Students'
        sampleFileUrl={sampleFileUrl}
        sampleFileName='student_sample.csv'
        onClose={handleFileUpload}
      />
      <StudentEditorDialog open={editorOpen} onClose={handleClose} fetchStudentList={fetchStudentList} />
    </div>
  );
};

function buildSampleFile(attributes: Attribute[], isSchool: boolean) {
  const attributeHeaders = attributes.map(a => a.code);
  const data = [
    ['first_name', 'last_name', 'roll_number', isSchool ? 'grade_level_code' : 'program_code', 'term_code', ...attributeHeaders],
    ['Sarah', 'Matanga', '23CS001', isSchool ? 'G1' : 'BSCS', '1', ...attributeHeaders]
  ];
  return createCSVBlobURL(data);
}

export default StudentList;
