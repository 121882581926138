import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import { useFeeRuleStore, useAccountStore } from 'store';
import {
  Table,
  Column,
  Input,
  RadioGroup,
  Radio,
  Button,
} from 'views/components/elements';
import { Active, Head, Inactive, FilterForm } from 'views/components/styled';
import { FeeRule } from 'models';
import { FeeRuleDialog } from 'views/dialogs';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

export default function FeeRuleList() {
  const { hasAccess } = useAccountStore();
  const hasFeeRulewriteAccess = hasAccess(BEHAVIOR_ACTIONS.FeeRuleCreate);
  const hasFeeRuleEditAccess = hasAccess(BEHAVIOR_ACTIONS.FeeRuleUpdate);
  const columns = [
    { field: 'fee_type', label: 'fee type' },
    { field: 'term_number', label: 'term number', width: 150 },
    { field: 'amount', label: 'amount', width: 150, format: 'currency' },
    { field: 'priority', label: 'priority', width: 100 },
    {
      field: 'created_date_time',
      label: 'created',
      format: 'date',
      width: 120,
    },
    {
      field: 'is_active',
      label: 'status',
      valueGetter: row =>
        row.is_active ? <Active>Active</Active> : <Inactive>Inactive</Inactive>,
      width: 120,
    },
    {
      field: 'action',
      label: '',
      valueGetter: row => (
        <IconButton disabled={!hasFeeRuleEditAccess} onClick={createEditHandler(row)}>
          <EditIcon />
        </IconButton>
      ),
      width: 80,
    },
  ] as Column<FeeRule>[];

  const { feeRules, feeRule, fetchFeeRules, resetFeeRule, fetchFeeRule } = useFeeRuleStore();
  const [filter, setFilter] = useState({ query: '', status: 'All' });
  const [editorOpen, setEditorOpen] = useState(false);

  useEffect(() => {
    if (!feeRules.length) {
      fetchFeeRules();
    }
  }, []);

  const createEditHandler = (feeRule: FeeRule) => async () => {
    await fetchFeeRule(feeRule.fee_rule_id);
    setEditorOpen(true);
  };

  const handleSubmit = (values: typeof filter) => {
    setFilter(values);
  };

  const rows = feeRules.filter(x => {
    return (
      (!filter.query ||
        x.fee_type.toLowerCase().includes(filter.query.toLowerCase())) &&
      (filter.status === 'All' || x.is_active === (filter.status === 'Active'))
    );
  });

  const handleAdd = () => {
    resetFeeRule();
    setEditorOpen(true);
  };

  const handleClose = () => {
    setEditorOpen(false);
  };

  return (
    <>
      <Head>Fee Rules</Head>
      <div className='row head'>
        <FilterForm initialValues={filter} onSubmit={handleSubmit}>
          <Input
            name='query'
            placeholder='Search Fee Rules'
            startAdornment={<SearchIcon />}
          />
          <RadioGroup name='status' row>
            <Radio value='Active' label='Active' />
            <Radio value='Inactive' label='Inactive' />
            <Radio value='All' label='All' />
          </RadioGroup>
          <Button type='submit' variant='text'>
            Search
          </Button>
        </FilterForm>
        <Button disabled={!hasFeeRulewriteAccess} startIcon={<AddCircleOutlineIcon />} onClick={handleAdd}>
          Add Fee Rule
        </Button>
      </div>
      <Table rows={rows} columns={columns} />
      <FeeRuleDialog
        open={editorOpen}
        onClose={handleClose}
        feeRule={feeRule}
      />
    </>
  );
}
