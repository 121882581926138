import { SyntheticEvent, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TagIcon from '@mui/icons-material/Tag';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ReceiptTemplate, ReceiptTemplateOptions } from 'models';
import { Form, Input, CodeEditor } from 'views/components/elements';
import { useReceiptTemplateStore } from 'store/receipt-template';
import { defaultTemplate, getTemplateData } from './TemplateData';
import PatternEditor from './PatternEditor';
import HtmlPreview, { minifyHTML } from './HtmlPreview';
import { useAccountStore, useInstituteStore } from 'store';
import { ToggleButton } from './Elements';
import { FlexRow } from 'views/components/styled';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const Head = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 24,
});

const Content = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
});

const FormField = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 8,
  '& label': {
    fontSize: 14,
  },
});

const StyledTab = styled(Tab)({
  textTransform: 'unset',
  minHeight: 'unset',
});

type ReceiptTemplateDialogProps = {
  open: boolean;
  onClose: () => void;
  receiptTemplate?: ReceiptTemplate;
};

export default function ReceiptTemplateDialog(
  props: ReceiptTemplateDialogProps
) {
  const {
    createReceiptTemplate,
    updateReceiptTemplate,
    enableReceiptTemplate,
    disableReceiptTemplate,
    fetchReceiptTemplates,
  } = useReceiptTemplateStore();
  const { business } = useInstituteStore();
  const { hasAccess } = useAccountStore();
  const hasReceiptTemplateEnableAccess = hasAccess(BEHAVIOR_ACTIONS.ReceiptTemplateEnable);
  const hasReceiptTemplateDisableAccess = hasAccess(BEHAVIOR_ACTIONS.ReceiptTemplateDisable);

  const [tab, setTab] = useState(1);
  const isEdit = Boolean(props.receiptTemplate?.receipt_template_id);

  const receiptTemplate = {
    name: props.receiptTemplate?.name || '',
    number_pattern: props.receiptTemplate?.number_pattern || '',
    template: props.receiptTemplate?.template || defaultTemplate,
    templateData: getTemplateData(business),
  };

  const handleSubmit = async (values: typeof receiptTemplate) => {
    const options = {
      name: values.name,
      number_pattern: values.number_pattern,
      template: minifyHTML(values.template),
    } as ReceiptTemplateOptions;

    if (isEdit) {
      await updateReceiptTemplate(
        options,
        props.receiptTemplate.receipt_template_id
      );
    } else {
      await createReceiptTemplate(options);
    }

    props.onClose();
  };

  const handleTabChange = (_: SyntheticEvent, tab: number) => setTab(tab);

  const handleToggle = async () => {
    if (props.receiptTemplate.is_active) {
      await disableReceiptTemplate(props.receiptTemplate.receipt_template_id);
    } else {
      await enableReceiptTemplate(props.receiptTemplate.receipt_template_id);
    }
    props.onClose();
    fetchReceiptTemplates();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen>
      <Head>
        <DialogTitle>{isEdit ? 'Edit' : 'Add'} Receipt Template</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form initialValues={receiptTemplate} onSubmit={handleSubmit}>
        <Content>
          <FlexRow justify='space-between'>
            <div className='row head'>
              <FormField>
                <label>Name</label>
                <Input name='name' placeholder='Name' />
              </FormField>
            </div>
            <ToggleButton
              isEdit={isEdit}
              isActive={props.receiptTemplate?.is_active}
              onClick={handleToggle}
              disabled={!(props?.receiptTemplate?.is_active ? hasReceiptTemplateDisableAccess: hasReceiptTemplateEnableAccess)}
            />
          </FlexRow>
          <Tabs value={tab} onChange={handleTabChange}>
            <StyledTab
              icon={<EditIcon />}
              iconPosition='start'
              label='Edit'
              disableRipple
              value={1}
            />
            <StyledTab
              icon={<DataObjectIcon />}
              iconPosition='start'
              label='Data'
              disableRipple
              value={2}
            />
            <StyledTab
              icon={<VisibilityIcon />}
              iconPosition='start'
              label='Preview'
              disableRipple
              value={3}
            />
            <StyledTab
              icon={<TagIcon />}
              iconPosition='start'
              label='Number pattern'
              disableRipple
              value={4}
            />
          </Tabs>
          {tab === 1 && (
            <CodeEditor
              name='template'
              height='calc(100vh - 250px)'
              language='html'
            />
          )}
          {tab === 2 && (
            <CodeEditor
              name='templateData'
              height='calc(100vh - 250px)'
              language='json'
              options={{ readOnly: true }}
            />
          )}
          {tab === 3 && (
            <HtmlPreview templateField='template' dataField='templateData' />
          )}
          {tab === 4 && <PatternEditor name='number_pattern' />}
        </Content>
      </Form>
    </Dialog>
  );
}
