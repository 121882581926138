import { ButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Student } from 'models';
import { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import { MenuButton, Tab, Button } from 'views/components/elements';
import {
  AddIcon,
  DeleteUserIcon,
  EditIcon,
  Icon,
  TickIcon,
} from 'views/components/icon';
import { ContentWrapper, FlexRow } from 'views/components/styled';
import StudentEditorDialog from 'views/dialogs/StudentEditor';
import { useStudentViewStore } from 'store/student';
import StudentHeader from 'views/components/StudentHeader';
import { useAccountStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const Title = styled('h1')({
  fontSize: 32,
  fontWeight: '500',
  marginBottom: '48px !important'
});

const ButtonAction = styled('div')({
  '& .css-1rk2t69-MuiButtonBase-root-MuiButton-root': {
    padding: '7.5px 14px !important',
  },
});

const StudentRow = styled(FlexRow)({
  marginBottom: 32
});

const StudentTab = styled(Tab)({
  marginBottom: 16
});

function StudentContainer(props: Student) {
  const [editorOpen, setEditorOpen] = useState(false);
  const navigate = useNavigate();
  const { hasAccess } = useAccountStore();

  const onEditStudent = () => {
    setEditorOpen(true);
  };

  const onAddReceipt = () => {
    navigate(`/receipts/new/student/${props.student_id}`);
  };

  const onEditorClose = () => {
    setEditorOpen(false);
  };

  function moveOnToFeesApply(student:Student){
    navigate(`/fees/applyFees?studentId=${student.student_id}`);
  }

  const hasStudentUpdateAccess = hasAccess(BEHAVIOR_ACTIONS.StudentUpdate);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hasStudentDisableAccess = hasAccess(BEHAVIOR_ACTIONS.StudentDisable);

  return (
    <>
      <StudentRow justify='space-between' align='start'>
        <StudentHeader {...props} />
        <ButtonAction>
          <ButtonGroup variant='contained'>
            <Button startIcon={<TickIcon />} onClick={()=> moveOnToFeesApply(props)}>Apply Fees</Button>
            <MenuButton
              menus={[
                {
                  label: 'Add Receipt',
                  icon: <AddIcon />,
                  onClick: onAddReceipt,
                },
                {
                  label: 'Edit Student',
                  icon: <EditIcon />,
                  onClick: onEditStudent,
                  disabled: !hasStudentUpdateAccess
                },
                {
                  label: 'Delete Student',
                  icon: <DeleteUserIcon />,
                  disabled: true  /* update with hasStudentDeleteAccess later*/
                },
              ]}
            >
              <Icon icon='expand_more_icon' iconValue='expand_more' />
            </MenuButton>
          </ButtonGroup>
        </ButtonAction>
      </StudentRow>
      <StudentEditorDialog
        open={editorOpen}
        onClose={onEditorClose}
        student={props}
      />
    </>
  );
}

function StudentTabs() {
  return (
    <>
      <StudentTab>
        <NavLink to='fees'>Fees</NavLink>
        <NavLink to='payments'>Payments</NavLink>
      </StudentTab>
      <Outlet />
    </>
  );
}

const StudentDetail = () => {
  const { studentId } = useParams();
  const { student, fetchStudent } = useStudentViewStore();

  useEffect(() => {
    fetchStudent(studentId);
  }, [studentId]);

  return (
    <ContentWrapper>
      <Title>Student Details</Title>
      {student && (
        <>
          <StudentContainer {...student} />
          <StudentTabs />
        </>
      )}
    </ContentWrapper>
  );
};

export default StudentDetail;
