import Radio from '@mui/material/Radio';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

const Label = styled(FormControlLabel)({
  fontSize: 14
});

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export default function CustomizedRadio(
  props: PartialBy<FormControlLabelProps, 'control'>
) {
  return <Label {...props} control={<Radio />} />;
}
