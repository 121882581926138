import { Popover, styled, CardContent, Divider, Paper } from '@mui/material';
import { Student } from 'models';
import {
  AmountLabel,
  Avatar,
  FlexColumn,
  FlexRow,
  Subtitle,
  Title,
} from '../styled';
import { currencyFormat, getAvatarText } from 'views/shared/utils/string-utils';

type PopoverProps = {
  open?: boolean;
  anchorEl?: any;
  onClose?: any;
  student?: Student;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const StyledPopover = styled(Popover)({
  '.MuiPaper-root': {
    padding: 10,
    minWidth: '280px', 
    maxWidth: 'none', 
    width: 'auto',   
  },
});

const ContainerDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const InsightsWrapper = styled('div')({
  overflowY: 'auto',
  padding: '10px 20px',
  maxHeight: 'calc(100vh - 120px)',
});

const StyledKey = styled('div')({
  opacity: 0.7,
  textTransform: 'capitalize',
  padding: '0px !important',
  whiteSpace: 'nowrap',  
  overflow: 'hidden'
});

const StyledGrid = styled(FlexColumn)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '0px',
});

const StyledRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  whiteSpace: 'nowrap', 
  '& > div': {
    flex: 'none',  
  },
});

const StyledMetadataContainer = styled('div')({
  top: 0,
  backgroundColor: '#fff',
  zIndex: 2,
  fontSize:14,
  padding:0
});

const InsightsSection = (props: Student) => {
  const {
    total_amount,
    concession_amount,
    scholarship_amount,
    amount_capturable,
  } = props;
  const paidAmount =
    (props.captured_legacy ?? 0) +
    (props.captured_offline ?? 0) +
    (props.captured_online ?? 0);

  return (
    <FlexColumn size='xs'>
      <Title>Insights</Title>
      <FlexColumn size='xxs' style={{rowGap:8}} >
        <FlexRow style={{ padding: '0px',height:14,fontSize:14 }}>
          <Subtitle style={{ opacity: 0.6 }}>Total Fees:&nbsp;</Subtitle>
          <AmountLabel>{currencyFormat(total_amount)}</AmountLabel>
        </FlexRow>
        <FlexRow style={{ padding: '0px',height:14,fontSize:14 }}>
          <Subtitle style={{ opacity: 0.6 }}>Concession:&nbsp;</Subtitle>
          <AmountLabel>{currencyFormat(concession_amount)}</AmountLabel>
        </FlexRow>
        <FlexRow style={{ padding: '0px',height:14,fontSize:14 }}>
          <Subtitle style={{ opacity: 0.6 }}>Scholarship:&nbsp;</Subtitle>
          <AmountLabel>{currencyFormat(scholarship_amount)}</AmountLabel>
        </FlexRow>
        <FlexRow style={{ padding: '0px',height:14,fontSize:14 }}>
          <Subtitle style={{ opacity: 0.6 }}>Fees Paid:&nbsp;</Subtitle>
          <AmountLabel>{currencyFormat(paidAmount)}</AmountLabel>
        </FlexRow>
        <FlexRow style={{ padding: '0px',height:14,fontSize:14 }}>
          <Subtitle style={{ opacity: 0.6 }}>Balance:&nbsp;</Subtitle>
          <AmountLabel>{currencyFormat(amount_capturable)}</AmountLabel>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};

function StudentMetadata(student?: Student) {
  if (!student?.metadata) {
    return <></>;
  }
  const avatarText = getAvatarText(
    `${student.first_name} ${student.last_name}`
  );
  const metadataKeys = Object.keys(student.metadata);

  return (
    <StyledMetadataContainer>
      <CardContent style={{ paddingBottom: '16px' }}>
        <FlexRow style={{ alignItems: 'center', gap: '10px', marginBottom: '16px' }}>
          <Avatar>{avatarText}</Avatar>
          <FlexColumn size='xs'>
            <Title>
              {student.first_name} {student.last_name}
            </Title>
            <span>{student.roll_number}</span>
          </FlexColumn>
        </FlexRow>
        <StyledGrid>
          {metadataKeys.map(key => (
            <StyledRow
              key={key}
              style={{ padding: '5px', alignItems: 'center' }}
            >
              <StyledKey style={{ paddingRight: '10px' }}>{key}:&nbsp;</StyledKey>
              <div style={{ padding: '0px' }}>{student.metadata[key]}</div>
            </StyledRow>
          ))}
        </StyledGrid>
      </CardContent>
    </StyledMetadataContainer>
  );
}

const AttributesPopover = (props: PopoverProps) => {
  return (
    <StyledPopover
      id='mouse-over-popover'
      sx={{ pointerEvents: 'none' }}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={props.onClose}
      disableRestoreFocus
      onMouseEnter={props.onMouseEnter}
      onMouseLeave= {props.onMouseLeave}
    >
      <Paper sx={{ pointerEvents: 'auto' ,boxShadow:'none'}}>
        <ContainerDiv>
          <StudentMetadata {...props.student} />
          <Divider style={{borderWidth:'1px'}}/>
          <InsightsWrapper>
            <InsightsSection {...props.student} />
          </InsightsWrapper>
        </ContainerDiv>
      </Paper>
    </StyledPopover>
  );
};

export default AttributesPopover;
