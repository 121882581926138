import { StudentFeeView } from 'models';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStudentViewStore } from 'store/student';
import { Column, Table } from 'views/components/elements';

const StudentFees = () => {
  const { fetchFees, fees } = useStudentViewStore();
  const { studentId } = useParams();

  useEffect(() => {
    fetchFees(studentId);
  }, [studentId]);

  const columns = [
    {
      field: 'fee_type',
      label: 'Fees',
      valueGetter: row => `${row.fee_type} - term ${row.term_number}`
    },
    {
      field: 'amount',
      label: 'Amount',
      width: 120,
      format: 'currency',
    },
    {
      field: 'captured_offline',
      label: 'Paid Offline',
      width: 120,
      format: 'currency',
    },
    {
      field: 'captured_online',
      label: 'Paid Online',
      width: 120,
      format: 'currency',
    },
    {
      field: 'amount_capturable',
      label: 'balance',
      format: 'currency',
      width: 120,
    },
    {
      field: 'student_fee_status',
      label: 'Status',
      width: 120,
    },
    {
      field: 'created_date_time',
      label: 'created',
      width: 120,
      format: 'date',
    },
  ] as Column<StudentFeeView>[];

  return (
    <div>
      {fees?.length > 0 && <Table columns={columns} rows={fees} stickyHeader />}
    </div>
  );
};

export default StudentFees;
