import { FeeType } from 'models';
import { StudentFeeView } from '../../../models/StudentFee';
import { Column } from '../../components/elements/Table';

export const columns = [
  { field: 'roll_number', label: 'roll no.', sortable: true, width: 150 },
  {
    field: 'first_name',
    label: 'name',
    valueGetter: row => `${row.first_name} ${row.last_name}`,
    sortable: true,
    width: 200,
  },
  { field: 'fee_type', label: 'fee type', sortable: true },
  { field: 'term_number', label: 'term', sortable: true, width: 94 },
  { field: 'amount', label: 'amount', format: 'currency', width: 120 },
  {
    field: 'captured_offline',
    label: 'paid offline',
    format: 'currency',
    width: 120,
  },
  {
    field: 'captured_online',
    label: 'paid online',
    format: 'currency',
    width: 120,
  },
  {
    field: 'amount_capturable',
    label: 'balance',
    format: 'currency',
    sortable: true,
    width: 120,
  },
  { field: 'student_fee_status', label: 'status', width: 100 },
  {
    field: 'created_date_time',
    label: 'created',
    sortable: true,
    width: 120,
    format: 'date',
  },
] as Column<StudentFeeView>[];

export const feeTypeColumns = [
  { field: 'name', label: 'Fee Head' },
  { field: 'bank_account_name', label: 'Bank Account', width: 250 },
  { field: 'receipt_template_name', label: 'Receipt Template', width: 250 },
  { field: 'term_number', label: 'Term Number', width: 120,render:'term_number'},
  { field: 'amount', label: 'Amount', width: 120,render:'amount'},
  { field: 'due_date', label: 'Due Date', width: 200,render:'due_date'},
] as Column<FeeType>[];
