import styled from '@emotion/styled';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip } from '@mui/material';
import { createStudentFee } from 'gateways/student-fee';
import { FeeType, Student, StudentFeeCreateOptions, StudentFeeItem } from 'models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeeTypeStore } from 'store/fee-type';
import { useStudentListStore } from 'store/student';
import {
  Button,
  Form,
  Input,
  Radio,
  RadioGroup,
  Table,
} from 'views/components/elements';
import AdvancedFilterDialog from 'views/components/elements/AdvancedFilterDialog';
import { FilterForm, FlexColumn, FlexRow } from 'views/components/styled';
import { columns } from '../students/Column';
import { feeTypeColumns } from './Columns';
import AlertNotifier, { Position } from 'views/components/elements/AlertNotifier';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const StyledRow = styled(FlexRow)({
  justifyContent: 'start',
  display:'block'
});

const StyledDiv = styled('div')({
  opacity:0.6
});

const StyledButton = styled('div')({
  marginTop:20
});

type StudentProps = {
  gotoPreviousPage?: any;
  goToFeeApply?: any;
  studentCounts?: any;
  studentId?:any;
};

export function SearchStudent(props: StudentProps) {
  const { student, fetchStudentList, selectStudents } = useStudentListStore();
  const {
    students: studentList,
    filter: studentFilter,
    hasNextPage,
    selected: draftListSelected,
  } = student;
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [advSearchOpen, setAdvSearchOpen] = useState(false);
  const [isFeeType, setIsFeeType] = useState(false);
  const navigate = useNavigate();

  const filter = { query: studentFilter.query || '' };

  useEffect(()=>{
    selectStudents([]);
  },[]);
  const handleSubmit = (values: typeof filter) => {
    const updatedFilter = Object.assign({}, filter, {
      page: 1,
      query: values.query,
    });

    fetchStudentList(updatedFilter);
  };

  const handleAdvancedSearchClick = () => {
    setAdvSearchOpen(true);
  };

  const handleAdvancedSearchClose = () => {
    setAdvSearchOpen(false);
  };

  const clearFilter = () => {
    setIsFilterApplied(false);
    fetchStudentList();
  };

  const handleSort = (field: string, order: string) => {
    const filter = Object.assign({}, studentFilter, {
      sortColumn: field,
      sortDirection: order,
      page: 1,
    });

    fetchStudentList(filter);
  };

  const handleNextPage = () => {
    const filter = Object.assign({}, studentFilter, {
      page: studentFilter.page + 1,
    });
    fetchStudentList(filter);
  };

  const selected = studentList.filter(x =>
    draftListSelected.includes(x.student_id)
  );

  const gotoPreviousPage = () => {
    clearSelectedStudents();
    props.gotoPreviousPage('back');
  };

  const goToFeeApply = () => {
    setIsFeeType(false);
  };

  const handleSelectionChange = (rows: Student[]) => {
    selectStudents(rows.map(x => x.student_id));
  };

  const moveToFeeType = () => {
    setIsFeeType(true);
  };

  const clearSelectedStudents = () =>{
    selectStudents([]);
  };

  const returnHomePage = () =>{
    navigate('/fees/pending');
  };

  return (
    <>
      {!isFeeType && !props.studentId ? (
        <div className='row action'>
          <FlexRow justify='space-between' size='xs' style={{width:'100%'}}>
            <FlexRow>
              <Tooltip title='Back to previous page' arrow>
                <div onClick={gotoPreviousPage} style={{cursor:'pointer',marginTop:4}}>
                  <ArrowCircleLeftIcon color={'primary'}/>
                </div>
              </Tooltip>
              <h1>Select Students</h1>
            </FlexRow>
            <FlexColumn>
              {draftListSelected?.length > 0 && (
                <StyledDiv>{draftListSelected?.length} Students selected</StyledDiv>
              )}
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <Form
              initialValues={filter}
              onSubmit={handleSubmit}
              validateOnChange={false}
            >
              <Input
                name='query'
                placeholder='Search Students'
                startAdornment={<SearchIcon />}
              />
            </Form>
            <IconButton onClick={handleAdvancedSearchClick}>
              <FilterListIcon />
            </IconButton>
            {isFilterApplied && (
              <Button variant='text' color='secondary' onClick={clearFilter}>
                Clear filters
              </Button>
            )}
          </FlexRow>
          <AdvancedFilterDialog
            open={advSearchOpen}
            onClose={handleAdvancedSearchClose}
            searchModule={'Student'}
            setIsFilterApplied={setIsFilterApplied}
          />
        </div>
      ) : (
        <SearchFeeTypes
          goToFeeApply={goToFeeApply}
          studentCounts={draftListSelected}
          studentId={props.studentId}
        />
      )}
      {!isFeeType && (
        <><Table
          columns={columns}
          rows={studentList}
          stickyHeader
          onSortChange={handleSort}
          sortingField={studentFilter.sortColumn}
          sortingOrder={studentFilter.sortDirection}
          hasMoreRows={hasNextPage}
          infiniteScroll
          onNextPage={handleNextPage}
          selectable
          selected={selected}
          onSelectionChange={handleSelectionChange} />
        <FlexRow style={{marginTop:'24px'}}>
          <Button color='primary' onClick={moveToFeeType} disabled={!draftListSelected?.length}>
              Next
          </Button>
          <Button color='secondary' onClick={clearSelectedStudents}>Clear Selection</Button>
          <Button variant='text' color='secondary' onClick={returnHomePage}>
              Cancel
          </Button>
        </FlexRow></>
      )}
 
    </>
  );
}

export function SearchFeeTypes(props: StudentProps) {
  const { feeTypes, fetchFeeTypes,selectFeesTypes,selected: draftListSelected, } =
  useFeeTypeStore();
  const [alert, setAlert] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [feeFormValues, setFeeFormValues] = useState<{ [feeTypeId: number]: any }>({});
  const alertPosition:Position =  {vertical:'bottom',horizontal:'right'};

  const goToFeeApply = () => {
    if(props.studentId){
      clearSelectedFees();
      navigate(`/students/${props.studentId}/fees`);
    }else{
      clearSelectedFees();
      props.goToFeeApply('back');
    }
  };

  useEffect(() => {
    if (!feeTypes.length) {
      fetchFeeTypes();
    }
  }, []);

  const handleSelectionChange = (rows: FeeType[]) => {
    selectFeesTypes(rows.map(x => x.fee_type_id));
  };

  const selected = feeTypes?.filter(x =>
    draftListSelected?.includes(x.fee_type_id)
  );

  const clearSelectedFees = () =>{
    selectFeesTypes([]);
    feeTypes.forEach(res=>{
      delete res.amount;
      delete res.due_date;
      delete res.term_number;
    });
  };

  function handleInputChange(feeTypeId: any, field: string, value: any) {
    const dd = feeTypes.find(res=>res.fee_type_id == feeTypeId);
    if(field == 'amount'){
      dd.amount = +value;
    }
    if(field == 'term_number'){
      dd.term_number = +value;
    }
    if(field == 'due_date'){
      dd.due_date = value;
    }
    setFeeFormValues((prevState) => ({
      ...prevState,
      [feeTypeId]: {
        ...prevState[feeTypeId],
        [field]: value,
      },
    }));
  }

  const handleCloseAlert = () => {
    setAlertOpen(false); 
  };

  const returnHomePage = () =>{
    navigate('/fees/pending');
  };

  const applyFees = async() => {
    const filterFees = feeTypes.filter(res=>draftListSelected?.some(re=> re == res.fee_type_id));
    const currentDate = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
    const oneYearAhead  = new Date();
    oneYearAhead.setFullYear(currentDate.getFullYear() + 1);
    if (filterFees.some(re => !re.amount || !re.due_date || !re.term_number)) {
      setAlert({
        message: 'Need to fill selected fee types fee amount,due date and term number!',
        severity: 'warning',
      });
      setAlertOpen(true);
      return;
    }
    if (filterFees.some(re => {
      const dueDate = new Date(re.due_date);
      return !(dueDate >= sixMonthsAgo && dueDate <= oneYearAhead);
    })
    ) {
      setAlert({
        message: 'Due date must be between the last 6 months and next 1 year!',
        severity: 'warning',
      });
      setAlertOpen(true);
      return;
    }
    if(!filterFees.some(re=> re.term_number >=1 && re.term_number <= 99)){
      setAlert({
        message: 'Enter a valid term number!',
        severity: 'warning',
      });
      setAlertOpen(true);
      return;
    }
    const studentFeeItems: StudentFeeItem[] = [];
    filterFees.forEach(response => {
      const studentFeeItem: StudentFeeItem = {
        fee_type_id: response.fee_type_id,
        amount: response.amount,
        term_number: response.term_number
      };
  
      studentFeeItems.push(studentFeeItem);
    });
    const studentFeeCreateOptions:StudentFeeCreateOptions = {
      fees:studentFeeItems,
      students:props?.studentCounts || [props.studentId]
    };
    await createStudentFee(studentFeeCreateOptions);
    feeTypes.forEach(res=>{
      delete res.amount;
      delete res.due_date;
      delete res.term_number;
    });
    selectFeesTypes([]);
    navigate(`/fees/draft?applied=${true}`);
  };

  return (
    <>
      <FlexRow justify='space-between' size='xs' style={{width:'100%',marginBottom:20}}>
        <FlexRow>
          <Tooltip title='Back to previous page' arrow>
            <div onClick={goToFeeApply} style={{cursor:'pointer',marginTop:4}}>
              <ArrowCircleLeftIcon color={'primary'}/>
            </div>
          </Tooltip>
          <h1>Select Fee Type</h1>
        </FlexRow>
        <FlexColumn>
          {props.studentId ? 1 : props?.studentCounts?.length} Students Selected
          {draftListSelected?.length > 0 && (
            <StyledDiv>with {draftListSelected?.length} Fee types</StyledDiv>
          )}
        </FlexColumn>
      </FlexRow>
      <Table
        columns={feeTypeColumns}
        rows={feeTypes}
        stickyHeader
        infiniteScroll
        selectable
        selected={selected}
        onSelectionChange={handleSelectionChange}
        handleInputChange={handleInputChange}
        isFeesType={true}
      />
      <AlertNotifier {...alert}open={alertOpen} onClose={handleCloseAlert} position={alertPosition}/>
      {feeTypes.length > 0 && <FlexColumn>
        <FlexRow style={{marginTop:'24px'}}>
          <Button color='primary' onClick={applyFees} disabled={!draftListSelected?.length}>Apply Fees</Button>
          <Button color='secondary' onClick={clearSelectedFees}>Clear Selection</Button>
          <Button variant='text' color='secondary' onClick={returnHomePage}>
              Cancel
          </Button>
        </FlexRow>
      </FlexColumn>}
    </>
  );
}

function ApplyFeesList() {
  const [filter, setFilter] = useState({ status: '' });
  const [isManualApply, setIsManualApply] = useState(false);
  const [studentId,setStudentId] = useState('');

  const handleSubmit = (values: typeof filter) => {
    setFilter(values);
    if (values.status == 'Manual fee apply') {
      setIsManualApply(true);
    } else {
      setIsManualApply(false);
    }
  };
  useEffect(()=>{
    const myParam = new URLSearchParams(location?.search). get('studentId'); 
    setStudentId(myParam);
  },[]);
  const gotoPreviousPage = () => {
    setIsManualApply(false);
  };

  return (
    <>
      {!isManualApply && !studentId ? (
        <>
          <div className='row head'>
            <h1>Apply Fees</h1>
          </div><FilterForm initialValues={filter} onSubmit={handleSubmit}>
            <StyledRow>
              <h3>How do you want to apply?</h3>
              <div>
                <RadioGroup name='status' column>
                  <Radio
                    value='Apply based on fee rules'
                    label='Apply based on fee rules' />
                  <Radio value='Manual fee apply' label='Manual fee apply' />
                </RadioGroup>
              </div>
              <StyledButton>
                <Button type='submit'>Next</Button>
              </StyledButton>
            </StyledRow>
          </FilterForm></>
      ) : (
        <>{!studentId ? <SearchStudent gotoPreviousPage={gotoPreviousPage} studentId={studentId}/> :
          <SearchFeeTypes
            studentId={studentId}
          />
        }</>
        
      )}
    </>
  );
}

export default ApplyFeesList;
