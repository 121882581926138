import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CustomAttributes,
  Form,
  Input,
  Select,
  buildFormMetadata,
  metadataToJson,
} from 'views/components/elements';
import { FeeRule, FeeRuleCreateOptions, FeeRuleUpdateOptions } from 'models';
import { useFeeTypeStore } from 'store/fee-type';
import { useEffect } from 'react';
import { useAccountStore, useAttributeStore, useFeeRuleStore } from 'store';
import { Head, Content, Actions, FormField, ToggleButton } from './Elements';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

const priorities = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

type FeeRuleDialogProps = {
  feeRule?: FeeRule;
  open: boolean;
  onClose: () => void;
};

export default function FeeRuleDialog(props: FeeRuleDialogProps) {
  const { createFeeRule, updateFeeRule, enableFeeRule, disableFeeRule, fetchFeeRules } = useFeeRuleStore();
  const { feeTypes, fetchFeeTypes } = useFeeTypeStore();
  const { hasAccess } = useAccountStore();
  const hasFeeRuleEnableAccess = hasAccess(BEHAVIOR_ACTIONS.FeeRuleEnable);
  const hasFeeRuleDisableAccess = hasAccess(BEHAVIOR_ACTIONS.FeeRuleDisable);
  const attributes = useAttributeStore(s =>
    s.attributes.filter(a => a.usages.some(u => u.category === 'FeeRule'))
  );

  useEffect(() => {
    if (!feeTypes.length) {
      fetchFeeTypes();
    }
  }, []);

  const isEdit = Boolean(props.feeRule?.fee_rule_id);

  const feeRule = {
    term_number: props.feeRule?.term_number,
    amount: props.feeRule?.amount,
    priority: props.feeRule?.priority || '',
    metadata: buildFormMetadata(props.feeRule?.metadata, attributes),
    fee_type_id: props.feeRule?.fee_type_id || '',
  };

  const handleToggle = async () => {
    if(props.feeRule.is_active){
      await disableFeeRule(props.feeRule.fee_rule_id);
    }else{
      await enableFeeRule(props.feeRule.fee_rule_id);
    }
    props.onClose();
    fetchFeeRules();
  };

  const handleSubmit = async (values: typeof feeRule) => {
    const options = {
      term_number: values.term_number,
      amount: values.amount,
      priority: values.priority,
      metadata: metadataToJson(values.metadata),
    };

    if (isEdit) {
      const model = {
        ...options,
        is_active: props.feeRule.is_active,
      } as FeeRuleUpdateOptions;

      await updateFeeRule(model, props.feeRule.fee_rule_id);
    } else {
      const model = {
        ...options,
        fee_type_id: values.fee_type_id,
      } as FeeRuleCreateOptions;

      await createFeeRule(model);
    }

    props.onClose();
  };


  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='sm' fullWidth>
      <Head>
        <DialogTitle>{isEdit ? 'Edit' : 'Add'} Fee Rule</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={feeRule}
        onSubmit={handleSubmit}
        render={({ values }) => (
          <>
            <Content>
              <FormField>
                <label>Fee Type</label>
                <Select
                  name='fee_type_id'
                  placeholder='Fee Type'
                  disabled={isEdit}
                >
                  {feeTypes.map((feeType, index) => (
                    <MenuItem
                      key={index}
                      value={feeType.fee_type_id}
                      disabled={!feeType.is_active}
                    >
                      {feeType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormField>
              <FormField>
                <label>Term Number</label>
                <Input
                  name='term_number'
                  placeholder='Term Number'
                  type='number'
                />
              </FormField>
              <CustomAttributes
                attributes={attributes}
                metadata={values.metadata}
                category='FeeRule'
              />
              <FormField>
                <label>Amount</label>
                <Input name='amount' placeholder='Amount' type='number' />
              </FormField>
              <FormField>
                <label>Priority</label>
                <Select name='priority' placeholder='Priority'>
                  {priorities.map((priority, index) => (
                    <MenuItem key={index} value={priority}>
                      {priority}
                    </MenuItem>
                  ))}
                </Select>
              </FormField>
            </Content>
            <Actions>
              {isEdit &&
                <div>
                  <ToggleButton
                    isEdit={isEdit}
                    isActive={props.feeRule?.is_active}
                    onClick={handleToggle}
                    disabled={!(props?.feeRule.is_active ? hasFeeRuleDisableAccess: hasFeeRuleEnableAccess)}
                  />
                </div>
              }
              <div>
                <Button type='submit'>Save Changes</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </div>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}
