import { useEffect, useState } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchIcon from '@mui/icons-material/Search';
import {
  Table,
  Input,
  Form,
  Button,
  MenuButton,
} from 'views/components/elements';
import { useAccountStore, useAttributeStore, useStudentFeeStore } from 'store';
import { Attribute, FileDetail, StudentFeeParams } from 'models';
import { columns } from './Columns';
import { FileUploadDialog } from 'views/dialogs';
import { createCSVBlobURL } from 'views/shared/utils/string-utils';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';

function FeesListPending() {
  const [importOpen, setImportOpen] = useState(false);
  const { pending, fetchFees, importFees } = useStudentFeeStore();
  const { attributes, fetchAttributes } = useAttributeStore();
  const {
    fees: pendingList,
    filter: pendingFilter,
    hasNextPage: hasNextPagePending,
  } = pending;
  const navigate = useNavigate();
  const {hasAccess} = useAccountStore();
  const hasFeesCreateAccess = hasAccess(BEHAVIOR_ACTIONS.FeesCreate);

  useEffect(() => {
    if (!pendingList.length) {
      fetchFees('pending', {
        statuses: ['Open', 'PartlyPaid', 'Overdue'],
      } as StudentFeeParams);
    }

    if (!attributes.length) {
      fetchAttributes();
    }
  }, []);

  const handleSort = (field: string, order: string) => {
    const filter = Object.assign({}, pendingFilter, {
      sortColumn: field,
      sortDirection: order,
      page: 1,
    });

    fetchFees('pending', filter);
  };

  const handleNextPage = () => {
    const filter = Object.assign({}, pendingFilter, {
      page: pendingFilter.page + 1,
    });

    fetchFees('pending', filter);
  };

  const filter = { query: pendingFilter.query || '' };

  const handleSubmit = (values: typeof filter) => {
    const newFilter = Object.assign({}, pendingFilter, {
      page: 1,
      query: values.query,
    });

    fetchFees('pending', newFilter);
  };

  const handleImport = () => {
    setImportOpen(true);
  };

  const handleFileUpload = async (value?: FileDetail) => {
    setImportOpen(false);
    if (value) {
      await importFees(value);
    }
  };

  const handleExport = () => {
    console.log('export clicked');
  };

  const sampleFileUrl = buildSampleFile(attributes);

  return (
    <>
      <div className='row action'>
        <Form
          initialValues={filter}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          <Input
            name='query'
            placeholder='Search Fees'
            startAdornment={<SearchIcon />}
          />
        </Form>
        <div>
          <ButtonGroup variant='contained'>
            <Button startIcon={<CheckCircleOutlineIcon />}  onClick={()=> navigate('/fees/applyFees')} disabled={!hasFeesCreateAccess}>Apply Fees</Button>
            <MenuButton
              menus={[
                {
                  label: 'Import',
                  icon: <FileUploadIcon />,
                  onClick: handleImport,
                },
                {
                  label: 'Export',
                  icon: <FileDownloadIcon />,
                  onClick: handleExport,
                },
              ]}
            >
              <ExpandMoreIcon fontSize='medium' />
            </MenuButton>
          </ButtonGroup>
        </div>
      </div>
      <Table
        columns={columns}
        rows={pendingList}
        stickyHeader
        onSortChange={handleSort}
        sortingField={pendingFilter.sortColumn}
        sortingOrder={pendingFilter.sortDirection}
        hasMoreRows={hasNextPagePending}
        infiniteScroll
        onNextPage={handleNextPage}
      />
      <FileUploadDialog
        open={importOpen}
        title='Import Fees'
        sampleFileUrl={sampleFileUrl}
        sampleFileName='student-fee_sample.csv'
        onClose={handleFileUpload}
      />
    </>
  );
}

export function buildSampleFile(attributes: Attribute[]) {
  const dueDate = moment(new Date()).add(15, 'day').format('YYYY-MM-DD');
  const attributeHeaders = attributes.filter(a => a.usages.some(u => u.category === 'StudentFee')).map(a => a.code);
  const data = [
    ['roll_number', 'fee_type', 'term_code', 'amount', 'captured_legacy', 'due_date', ...attributeHeaders],
    ['23CS001', 'Tuition Fees', '1', '2000.00', '', dueDate, ...attributeHeaders]
  ];
  return createCSVBlobURL(data);
}

export default FeesListPending;
