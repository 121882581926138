import { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

function FeesList() {
  const {pathname} = useLocation();
  const [isFees,setIsFeesApply] = useState(false);

  useEffect(()=>{
    setIsFeesApply(pathname.split('/').includes('applyFees'));
  },[pathname]);

  return (<>
    <div className='custom-table-wrapper'>
      {!isFees && <div className='row head'>
        <h1>Fees</h1>
        <div className='nav-header'>
          <NavLink to='draft'>To finalize</NavLink>
          <NavLink to='pending'>Yet to collect</NavLink>
          <NavLink to='completed'>Collected</NavLink>
          <NavLink to='advanced'>Advanced Search</NavLink>
        </div>
      </div>}
      <Outlet />
    </div>
  </>
  );
}

export default FeesList;
