import { listReceipts } from 'gateways/receipt';
import { getStudent, importStudents, listStudents, createStudent, updateStudent, enableStudent, disableStudent } from 'gateways/student';
import { listStudentFees } from 'gateways/student-fee';
import {
  StudentListParams,
  FileDetail,
  StudentCreateOptions,
  StudentUpdateOptions,
  StudentFeeView,
  StudentFeeParams,
  Receipt,
  ReceiptListParams,
  Student,
} from 'models';
import { create } from 'zustand';

interface StudentListView {
  filter: StudentListParams,
  students: Student[],
  hasNextPage: boolean,
  selected: string[]
  total: number
}

interface State {
  student: StudentListView;
}

interface Action {
  fetchStudentList: (params?: StudentListParams) => Promise<void>
  importStudents: (file: FileDetail) => Promise<void>
  selectStudents: (student: string[]) => void
}

const emptyStudentListView = {
  filter: {},
  students: [],
  hasNextPage: false,
  selected: []
} as StudentListView;

const useStudentListStore = create<State & Action>((set, get) => ({
  student: emptyStudentListView,
  fetchStudentList: async (params?: StudentListParams) => {
    params ??= {} as StudentListParams;
    params.sortColumn ??= 'roll_number';
    params.sortDirection ??= 'desc';
    params.limit ??= 100;
    params.page ??= 1;

    const page = await listStudents(params);

    set(state => {
      const data = {
        filter: params,
        hasNextPage: params.page < page.metadata.last_page,
        selected: [],
        students: params.page === 1 ? page.collection : state.student.students.concat(page.collection),
        total: page.metadata.total
      } as StudentListView;

      return { student: data };
    });

  },
  importStudents: async (file: FileDetail) => {
    await importStudents(file.location);

    const { fetchStudentList, student } = get();

    fetchStudentList({ ...student.filter, page: 1 });
  },
  selectStudents: (students: string[]) => set(state => ({
    student: { ...state.student, selected: students }
  }))
}));

interface StudentViewState {
  student?: Student;
  fees?: StudentFeeView[];
  receipts?: Receipt[];
}

interface StudentViewAction {
  fetchStudent: (studentId: string) => void
  createStudent: (student: StudentCreateOptions) => void
  updateStudent: (student: StudentUpdateOptions, studentId: string) => void,
  fetchFees: (studentId: string) => void,
  fetchReceipts: (studentId: string) => void
  enableStudent: (studentId: string) => Promise<void>
  disableStudent: (studentId: string) => Promise<void>
}

const useStudentViewStore = create<StudentViewState & StudentViewAction>((set, get) => ({
  fetchStudent: async (studentId: string) => {
    if (!studentId || get().student?.student_id === studentId) {
      return;
    }
    const student = await getStudent(studentId);
    set({ student });
  },
  createStudent: async (options: StudentCreateOptions) => {
    const student = await createStudent(options);
    get().fetchStudent(student.student_id);
  },
  updateStudent: async (options: StudentUpdateOptions, studentId: string) => {
    await updateStudent(options, studentId);
    const student = await getStudent(studentId);
    set({ student });
  },
  enableStudent: async (studentId: string) => {
    await enableStudent(studentId);
    const student = await getStudent(studentId);
    set({ student });
  },
  disableStudent: async (studentId: string) => {
    await disableStudent(studentId);
    const student = await getStudent(studentId);
    set({ student });
  },
  fetchFees: async (studentId: string) => {
    const { fees } = get();

    if (fees?.some(x => x.student_id = studentId)) {
      return;
    }

    const page = await listStudentFees({
      studentId,
      page: 1,
      limit: 1000,
      sortColumn: 'created_date_time',
      sortDirection: 'desc',
      statuses: ['Open', 'Overdue', 'Paid', 'PartlyPaid', 'PartlyRefund', 'Refund']
    } as StudentFeeParams);
    set({ fees: page.collection });
  },
  fetchReceipts: async (studentId: string) => {
    const { receipts } = get();
    if (receipts?.some(x => x.student_id === studentId)) {
      return;
    }

    const page = await listReceipts({
      studentId,
      statuses: ['Captured', 'Disputed', 'PartiallyRefunded', 'Refunded'],
      page: 1,
      limit: 1000,
      sortColumn: 'created_date_time',
      sortDirection: 'desc'
    } as ReceiptListParams);

    set({ receipts: page.collection });
  },
}));

export { useStudentListStore, useStudentViewStore };
