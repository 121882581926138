import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Student, StudentListParams } from 'models';
import {
  Button,
  Form,
  Input,
  CustomAttributes,
  buildFormMetadata,
} from 'views/components/elements';
import { FlexRow, FormField } from 'views/components/styled';
import { useStudentListStore, useStudentViewStore } from 'store/student';
import { useAccountStore, useAttributeStore } from 'store';
import { Head, Content, Actions, ToggleButton } from './Elements';
import { useState } from 'react';
import { Alert } from '@mui/material';
import { BEHAVIOR_ACTIONS, validateMetadata } from 'views/shared/utils/validation-utils';
import { useNavigate } from 'react-router-dom';

type StudentProps = {
  student?: Student;
  open: boolean;
  onClose: () => void;
  fetchStudentList?: (params?: StudentListParams) => Promise<void>;
  onUpdateStudent?: () => void;
};

interface StudentForm {
  first_name: string
  last_name: string
  roll_number: string
  metadata: any[]
}

export default function StudentEditorDialog(props: StudentProps) {
  const { createStudent, updateStudent, enableStudent, disableStudent } = useStudentViewStore();
  const {fetchStudentList} = useStudentListStore();
  const attributes = useAttributeStore(state =>
    state.attributes.filter(a => a.is_active && a.usages.some(u => u.category === 'Student'))
  );
  const [errors,setErrors]= useState([]);
  const navigate = useNavigate();
  const { hasAccess } = useAccountStore();
  const hasStudentEnableAccess = hasAccess(BEHAVIOR_ACTIONS.StudentEnable);
  const hasStudentDisableAccess = hasAccess(BEHAVIOR_ACTIONS.StudentDisable);

  const isEdit = Boolean(props.student?.student_id);
  const student = {
    first_name: props.student?.first_name || '',
    last_name: props.student?.last_name || '',
    roll_number: props.student?.roll_number || '',
    metadata: buildFormMetadata(props.student?.metadata, attributes),
  } as StudentForm;

  const handleSubmit = async (values: StudentForm) => {
    setErrors([]);
    const errorObjs = [];

    const options = {
      first_name: values.first_name,
      last_name: values.last_name,
      roll_number: values.roll_number,
      metadata: {},
    };

    if (!values.first_name) {
      errorObjs.push('First Name is required.');
    }
    if (!values.last_name) {
      errorObjs.push('Last Name is required.');
    }
    if (!values.roll_number) {
      errorObjs.push('Roll Number is required.');
    }

    const { errors, validatedMetadata } = validateMetadata(values.metadata, attributes);
    errorObjs.push(...errors);
    options.metadata = validatedMetadata;
    if (errorObjs.length > 0) {
      setErrors(errorObjs);
      return; 
    }

    if (isEdit) {
      props.onClose();
      await updateStudent(options, props.student?.student_id);
    } else {
      props.onClose();
      await createStudent(options);
    }

    if (!isEdit) {
      props.fetchStudentList();
    } else {
      props.onUpdateStudent?.();
    }
  };

  const handleCancel = ()=>{
    setErrors([]);
    props.onClose();
  };

  const handleToggle = async ()=>{
    if(props.student?.is_active){
      await disableStudent(props?.student?.student_id);
    }else{
      await enableStudent(props?.student?.student_id);
    }
    props.onClose();
    fetchStudentList();
    navigate('/students');
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='sm' fullWidth>
      <Head>
        <DialogTitle>Add Student</DialogTitle>
        <IconButton onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={student}
        onSubmit={handleSubmit}
        render={({ values }) => (
          <>
            <Content>
              <FormField>
                <label>
                  First Name*
                </label>
                <Input name='first_name' placeholder='First Name' />
              </FormField>
              <FormField>
                <label>
                  Last Name*
                </label>
                <Input name='last_name' placeholder='Last Name' />
              </FormField>
              <FormField>
                <label>
                  Roll Number*
                </label>
                <Input name='roll_number' placeholder='Roll Number' />
              </FormField>
              <CustomAttributes
                attributes={attributes}
                metadata={values.metadata}
                category='Student'
              />
            </Content>
            {errors.map((error, index)=>(
              <Alert severity='error' key={index}>
                {error}
              </Alert>
            ))}
            <Actions style={{justifyContent:'end'}}>
              <div>
                <ToggleButton
                  isEdit={isEdit}
                  isActive={props.student?.is_active}
                  onClick={handleToggle}
                  disabled={!(props?.student?.is_active ? hasStudentDisableAccess: hasStudentEnableAccess)}
                />
              </div>
              <FlexRow size='xs'>
                <Button type='submit'>Save Student</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </FlexRow>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}
