import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import Layout from 'views/shared/layout';
import StudentList from 'views/pages/students/students-list';
import StudentDetail from 'views/pages/students/student-details';
import {
  FeesList,
  FeesListDraft,
  FeesListAdvanced,
  FeesListCompleted,
  FeesListPending,
  ApplyFeesList,
} from 'views/pages/fees';
import ReceiptList from 'views/pages/receipts/ReceiptList';
import Settings from 'views/pages/settings/Settings';
import InstituteDetail from 'views/pages/settings/institute/InstituteDetail';
import TemplateList from 'views/pages/settings/templates/TemplateList';
import AccountList from 'views/pages/settings/accounts/AccountList';
import FeeTypeList from 'views/pages/settings/fee-types/FeeTypeList';
import AddReceipt from 'views/pages/receipts/AddReceipt';
import ReportList from 'views/pages/reports/ReportList';
import DailyFeeCollection from 'views/pages/reports/DailyFeeCollection';
import StudentMetaData from 'views/pages/students/StudentMetaData';
import StudentFees from 'views/pages/students/StudentFees';
import StudentPayments from 'views/pages/students/StudentPayments';
import ConcessionList from 'views/pages/concessions/ConcessionList';
import AttributeList from 'views/pages/settings/attributes/AttributeList';
import FeeRuleList from 'views/pages/settings/fee-rules/FeeRuleList';
import LateChargeList from 'views/pages/settings/late-charge/LateChargeList';
import { useAccountStore } from 'store';
import { BEHAVIOR_ACTIONS } from 'views/shared/utils/validation-utils';
import Unauthorize from 'views/shared/unauthorized/unauthorized';
import GradeLevelList from 'views/pages/settings/GradeLevelList';
import ProgramList from 'views/pages/settings/ProgramList';

const RouterConfig = () => {
  // Hook must be used inside the component function body
  const { hasAccess } = useAccountStore();
  const getRedirect = (accessMap: object, defaultPath: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(accessMap).find(([_, hasAccess]) => hasAccess)?.[0] || defaultPath;
  };

  const accessMap = {
    '/students': hasAccess(BEHAVIOR_ACTIONS.StudentRead),
    '/fees': hasAccess(BEHAVIOR_ACTIONS.FeesRead),
    '/concessions': hasAccess(BEHAVIOR_ACTIONS.ConcessionRead),
    '/receipts': hasAccess(BEHAVIOR_ACTIONS.ReceiptRead),
    '/reports': hasAccess(BEHAVIOR_ACTIONS.ReportingReportRead),
  };

  const settingsAccessMap = {
    '/settings/institute': hasAccess(BEHAVIOR_ACTIONS.SettingsRead),
    '/settings/attributes': hasAccess(BEHAVIOR_ACTIONS.AttributeRead),
    '/settings/late-charge': hasAccess(BEHAVIOR_ACTIONS.LateChargeRead),
    '/settings/templates': hasAccess(BEHAVIOR_ACTIONS.ReceiptTemplateRead),
    '/settings/accounts': hasAccess(BEHAVIOR_ACTIONS.BankAccountRead),
    '/settings/fee-types': hasAccess(BEHAVIOR_ACTIONS.FeeTypeRead),
    '/settings/fee-rules': hasAccess(BEHAVIOR_ACTIONS.FeeRuleRead),
  };

  const defaultSettingRedirect = getRedirect(settingsAccessMap, '/access-denied');
  const defaultRedirect = getRedirect(accessMap, defaultSettingRedirect);

  return createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<Layout />}>
        <Route path='access-denied' element={<Unauthorize />} />
        <Route path='students' element={<StudentList />} />
        <Route path='students/:studentId' element={<StudentDetail />}>
          <Route path='fees' element={<StudentFees />} />
          <Route path='payments' element={<StudentPayments />} />
          <Route path='metadata' element={<StudentMetaData />} />
          <Route path='' element={<Navigate to='fees' />} />
        </Route>
        <Route path='fees' element={<FeesList />}>
          <Route path='applyFees' element={<ApplyFeesList />} />
          <Route path='draft' element={<FeesListDraft />} />
          <Route path='pending' element={<FeesListPending />} />
          <Route path='completed' element={<FeesListCompleted />} />
          <Route path='advanced' element={<FeesListAdvanced />} />
          <Route path='' element={<Navigate to='pending' />} />
        </Route>
        <Route path='concessions' element={<ConcessionList />} />
        <Route path='receipts' element={<ReceiptList />} />
        <Route path='receipts/new' element={<AddReceipt />} />
        <Route
          path='receipts/new/student/:studentId'
          element={<AddReceipt />}
        />
        <Route path='reports' element={<ReportList />}>
          <Route path='daily-fee-collection' element={<DailyFeeCollection />} />
          <Route path='' element={<Navigate to='daily-fee-collection' />} />
        </Route>
        <Route path='settings' element={<Settings />}>
          <Route path='institute' element={<InstituteDetail />} />
          <Route path='templates' element={<TemplateList />} />
          <Route path='accounts' element={<AccountList />} />
          <Route path='fee-types' element={<FeeTypeList />} />
          <Route path='attributes' element={<AttributeList />} />
          <Route path='fee-rules' element={<FeeRuleList />} />
          <Route path='fine-rules' element={<LateChargeList />} />
          <Route path='grade-levels' element={<GradeLevelList />} />
          <Route path='programs' element={<ProgramList />} />
          <Route path='' element={<Navigate to={defaultSettingRedirect} />} />
        </Route>
        <Route path='' element={<Navigate to={defaultRedirect} />} />
      </Route>
    )
  );
};

export { RouterConfig };
