import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {
  Button,
  Form,
  Input,
} from 'views/components/elements';
import { useAttributeStore } from 'store';
import { AttributeInput } from 'views/components/elements';
import { Actions, Content, Head } from 'views/dialogs/Elements';
import { FlexRow, FormField } from 'views/components/styled';
import { useStudentListStore } from 'store/student';
import { AttributeCategory } from 'models';

type props = {
  open: boolean;
  searchModule: AttributeCategory;
  onClose: () => void;
  setIsFilterApplied?: (value: boolean) => void;
};

function AdvancedFilterDialog(props: props) {
  const {open, searchModule, onClose, setIsFilterApplied} = props;
  const { fetchStudentList } = useStudentListStore();

  const attributes = useAttributeStore(state =>
    state.attributes.filter(a => a.is_active && a.is_allow_filter && a.usages.some(u => u.category === searchModule))
  );

  const initializeFormValues = () => {
    const initialValues = {};
    attributes.forEach(attribute => {
      initialValues[attribute.name] = null;
    });
    return initialValues;
  };

  const handleSubmit = values => {
    const metadata = Object.entries(values).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key] = value;
      }
      return acc;
    }, {});
    fetchStudentList(metadata);
    onClose();
    setIsFilterApplied(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <Head>
        <DialogTitle>Advanced Filter</DialogTitle>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={initializeFormValues()}
        onSubmit={handleSubmit}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render={({ resetForm }) => (
          <>
            <Content>
              <FormField>
                <label>Name/Roll Number</label>
                <Input name='query' placeholder='Name' />
              </FormField>
              {attributes.map(attribute => (
                <FormField key={attribute.attribute_id}>
                  <label>{attribute.label}</label>
                  <AttributeInput name={attribute.code} attribute={attribute} />
                </FormField>
              ))}
            </Content>

            <Actions style={{justifyContent: 'end'}}>
              <FlexRow size='xs'>
                <Button type='submit'>Filter</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </FlexRow>
            </Actions>
          </>
        )}
      />
    </Dialog>
  );
}

export default AdvancedFilterDialog;
